export const API_ENDPOINT = {
  POST: {
    order: '/v1/order',
    signature: '/v1/signature',
    cancelOrderByUserId: '/v1/cancelOrderByUserId',
  },
  GET: {
    orderUpdates: '/v1/order',
    nowServingOrderId: '/v1/nowServing',
    latestMints: '/v1/latestMints',
    nfts: '/v1/nfts',
  },
}
