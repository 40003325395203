/* eslint-disable @typescript-eslint/quotes */
import { useState, useEffect } from 'react'
import styled from 'styled-components/macro'

import { shared } from '@packages/ui'

import { COLORS } from 'src/shared/constants'

const { BREAKPOINTS } = shared

interface Item {
  id: number
  inscriptionLink: string
  'ordinalInscription #': string
  "mcPepe'sOrder #": string
  inscriber: string
}

export function OrdinalCollectionList() {
  const [data, setData] = useState<Item[]>([])

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        'https://api.sheety.co/0a8cd3ae0001d7dcda29203918e8da1a/mcPepe%E2%80%99sOrdinalCollection/sheet1'
      )
      const result = await response.json()
      const filteredData = result.sheet1.filter((item: Item) => item.id)
      setData(filteredData)
      console.log(filteredData)
    }

    fetchData()
  }, [])
  return (
    <Head>
      <Content>
        <Title>Ordinals Collection</Title>
      </Content>
      <Container>
        {data.map((item: Item) => (
          <Card key={item.id}>
            <A href={item.inscriptionLink} target='_blank'>
              <CardImage src={item.inscriptionLink.replace('inscription', 'content')} />
            </A>
            <CardTitle>Inscription #{item['ordinalInscription #']}</CardTitle>
            <CardDescription>McPepe's Order #{item["mcPepe'sOrder #"]}</CardDescription>
            <CardDescription>Inscriber: {item.inscriber}</CardDescription>
          </Card>
        ))}
      </Container>
    </Head>
  )
}

const Head = styled.div`
  margin: 0 auto;
  max-width: ${BREAKPOINTS.xxLarge};
  width: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 25px;
  gap: 30px;
`

const Title = styled(shared.Title)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 90px;
  line-height: 100px;
  color: ${COLORS.black};
  font-weight: 600;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 50px;
    line-height: 60px;
  }
`

const Container = styled.div`
  padding-top: 25px;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 15px;
`

const Card = styled.div`
  width: 300px;
  height: auto;
  border-radius: 18px;
  text-align: center;
  overflow: hidden;
  background-color: ${COLORS.white};
`

const CardImage = styled.img`
  cursor: pointer;
  width: 100%;
  height: auto;
  object-fit: cover;
  overflow: hidden;
  margin: -1px 0 0 0;
`

const A = styled.a`
  transition: 250ms;
  &:hover {
    opacity: 0.8;
  }
`

const CardTitle = styled.h2`
  padding-top: 10px;
  padding-bottom: 10px;
  color: ${COLORS.black};
  font-weight: 600;
  font-size: 18px;
  margin: 0;
`

const CardDescription = styled.p`
  color: ${COLORS.black};
  font-size: 15px;
  margin: 0;
  padding-bottom: 15px;
`
