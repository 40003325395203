import { useNavigate, useSearchParams } from 'react-router-dom'
import styled, { keyframes } from 'styled-components/macro'
import { useEffect, useRef, useState } from 'react'
import { useWeb3Modal } from '@web3modal/react'

import { api, shared, nfts, orders } from '@packages/ui'

import { OrderMintingModal } from 'src/orders'
import { ORDER_SEARCH_PARAMS, ROUTES } from 'src/pages/constants'
import { NFT_BASE_URL } from 'src/settings'

import DriveThroughImageBG from '../assets/drive-through-bg.png'
import DriveThroughImageCar from '../assets/drive-through-car.png'
import DriveThroughImageWheel from '../assets/wheel.png'

const { BREAKPOINTS } = shared
const CAR_ANIMATION_DURATION_SECONDS = 4

export function DriveThroughForm() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const imageFileName = searchParams.get(ORDER_SEARCH_PARAMS.imageFileName)
  const orderIdStr = searchParams.get(ORDER_SEARCH_PARAMS.orderId)
  const imageUrl = imageFileName ? nfts.getNFTImageUrl(imageFileName, NFT_BASE_URL) : ''
  const imageHash = imageFileName ? nfts.getImageHash(imageFileName) : undefined
  const [orderId, setOrderId] = useState<number>()

  const { isOpen: isWeb3ModalOpen } = useWeb3Modal()
  const errorRef = useRef<api.ApiErrorResponse>()
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
  const [isMintModalOpen, setIsMintModalOpen] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      if (!errorRef.current) {
        setIsMintModalOpen(true)
      }
    }, CAR_ANIMATION_DURATION_SECONDS * 1000)
  }, [])

  useEffect(() => {
    if (!imageFileName && !imageHash) {
      errorRef.current = {
        name: 'CustomError',
        title: 'Bad Request Man',
        message: 'Missing Image File Name',
      }
      setIsErrorModalOpen(true)
    }
  }, [imageFileName, imageHash])

  useEffect(() => {
    if (!orderIdStr) {
      errorRef.current = {
        name: 'CustomError',
        title: 'Bad Request Man',
        message: 'Missing orderId',
      }
      setIsErrorModalOpen(true)
    } else if (isNaN(parseInt(orderIdStr))) {
      errorRef.current = {
        name: 'CustomError',
        title: 'Bad Request Man',
        message: 'Invalid orderId',
      }
      setIsErrorModalOpen(true)
    } else {
      setOrderId(parseInt(orderIdStr))
    }
  }, [orderIdStr])

  function isInvalidImage() {
    errorRef.current = {
      name: 'CustomError',
      title: 'Bad Request Man',
      message: 'Invalid Image File Name',
    }
    setIsErrorModalOpen(true)
  }

  function closeMintModal() {
    setIsMintModalOpen(false)
  }

  function closeErrorModal() {
    setIsErrorModalOpen(false)
  }

  return (
    <>
      <DriveThroughBG />
      <CarWrap>
        <Car src={DriveThroughImageCar} />
        <Wheel1 src={DriveThroughImageWheel} />
        <Wheel2 src={DriveThroughImageWheel} />
      </CarWrap>
      <Image src={imageUrl} onError={() => isInvalidImage()} />
      <Container>
        {imageUrl && imageHash && orderId && (
          <>
            <OrderMintingModal
              isModalOpen={isMintModalOpen && !isWeb3ModalOpen}
              closeModal={closeMintModal}
              order={{
                id: orderId,
                uncompressedImageUrl: imageUrl,
                finalImageUrl: imageUrl,
                finalImageHash: imageHash,
                completedAtMs: Date.now(),
              }}
              displayOverlay
              onReorder={async () => navigate({ pathname: ROUTES.generate })}
            />
          </>
        )}
        {errorRef.current && (
          <orders.OrderErrorModal
            isModalOpen={isErrorModalOpen}
            closeModal={closeErrorModal}
            displayOverlay={isMintModalOpen}
            orderId={orderId}
            error={errorRef.current}
          />
        )}
      </Container>
    </>
  )
}

const DriveThroughBG = styled.div`
  height: 45vw;
  background-image: url(${DriveThroughImageBG});
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  width: 100%;
`

const CarWrap = styled.div`
  position: absolute;
  top: 86px;
  @keyframes run {
    0% {
      left: -150%;
    }
    100% {
      left: 0%;
    }
  }
  animation: run ${CAR_ANIMATION_DURATION_SECONDS}s cubic-bezier(0.165, 0.94, 0.64, 1) forwards;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    top: 80px;
  }
`

const Car = styled.img`
  width: 100vw;
`

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(600deg);
  }
`

const Wheel1 = styled.img`
  position: absolute;
  top: 38.7vw;
  left: 17.7vw;
  width: 13vw;
  animation: ${spin} ${CAR_ANIMATION_DURATION_SECONDS}s cubic-bezier(0.165, 0.94, 0.64, 1) forwards;
`

const Wheel2 = styled.img`
  position: absolute;
  top: 38.9vw;
  left: 73.6vw;
  width: 13vw;
  animation: ${spin} ${CAR_ANIMATION_DURATION_SECONDS}s cubic-bezier(0.165, 0.94, 0.64, 1) forwards;
`

const Container = styled.div`
  margin-top: -2px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  background-color: #6e6e6e;
  min-height: 400px;
`

const Image = styled.img`
  display: none;
`
