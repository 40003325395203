import { useEffect, useRef } from 'react'
import ReactModal from 'react-modal'
import moment from 'moment'
import styled from 'styled-components'

import { SignatureData, UserOrderResponse, CompletedOrder } from '@packages/interfaces'
import { api, shared } from '@packages/ui'

import { API_URL } from 'src/settings'
import { API_ENDPOINT } from 'src/apiEndpoints'
import { ReactComponent as Logo } from 'src/shared/assets/bw-logo.svg'

import { OrderSession } from '../interfaces'

const MAX_USER_PROMPT_DISPLAY_LENGTH = 120
const MAX_USER_PROMPT_MOBILE_DISPLAY_LENGTH = 70

const { BREAKPOINTS, COLORS } = shared

function trimPrompt(userPrompt: string, maxLength: number) {
  if (userPrompt.length > maxLength) {
    return userPrompt.substring(0, maxLength) + '...'
  }
  return userPrompt
}

interface Props extends shared.ModalProps {
  orderSession?: OrderSession
  nowServingOrderId: number
  onCompletedOrder: (completedOrder: CompletedOrder, signatureData?: SignatureData) => void
  onError: (error: api.ApiErrorResponse) => void
}

export function OrderReceiptModal({
  isModalOpen,
  closeModal,
  orderSession,
  nowServingOrderId,
  onCompletedOrder,
  onError,
  className,
}: Props) {
  const { width } = shared.useWindowDimensions()
  const isModalOpenRef = useRef<boolean>(false) // need useRef as the recursive subscribeToOrderEvents call scopes the old state value

  useEffect(() => {
    isModalOpenRef.current = isModalOpen
  }, [isModalOpen])

  useEffect(() => {
    if (orderSession) {
      subscribeToOrderEvents()
    }
  }, [orderSession])

  function handleClose() {
    closeModal()
  }

  async function subscribeToOrderEvents() {
    try {
      console.debug('subscribing to order events')
      const response = await api.apiFetch(
        `${API_URL}${API_ENDPOINT.GET.orderUpdates}?sessionId=${orderSession?.sessionId}`
      )
      const json = await response.json()

      if (response.status === 200) {
        const userOrderResponse: UserOrderResponse = json

        if (userOrderResponse.order) {
          console.debug(
            'order is completed',
            userOrderResponse.order,
            userOrderResponse.signatureData
          )
          onCompletedOrder(userOrderResponse.order, userOrderResponse.signatureData)
        } else if (userOrderResponse.error) {
          console.error('order error event', userOrderResponse.error)
          onError({
            name: 'ModelServerError',
            message: 'Error while processing order',
          })
        } else {
          throw new Error('Invalid order response: ' + userOrderResponse)
        }
      } else if (json && api.isApiErrorResponse(json)) {
        if (json.name === 'OrderPollingTimeoutError') {
          if (isModalOpenRef.current) {
            await subscribeToOrderEvents()
          }
        } else {
          onError(json)
        }
      } else {
        throw new Error(json)
      }
    } catch (error) {
      console.error(error)
      onError({ name: 'UnknownError' })
    }
  }

  if (!orderSession) return <></>

  let userPrompt = orderSession.userPrompt
  if (width > parseInt(BREAKPOINTS.medium)) {
    userPrompt = trimPrompt(userPrompt, MAX_USER_PROMPT_DISPLAY_LENGTH)
  } else {
    userPrompt = trimPrompt(userPrompt, MAX_USER_PROMPT_MOBILE_DISPLAY_LENGTH)
  }

  return (
    <ReactModal
      isOpen={isModalOpen}
      onRequestClose={handleClose}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={217}
      ariaHideApp={false}
      className='modalElement'
      contentElement={(props, children) => (
        <ModalElementWrapper {...props}>{children}</ModalElementWrapper>
      )}
      overlayElement={(props, contentElement) => (
        <ModalPortalWrapper {...props}>
          <shared.OverlayElement className={`modelOverlay ${className}`}>
            {contentElement}
          </shared.OverlayElement>
        </ModalPortalWrapper>
      )}
    >
      <shared.ModalContainer>
        <Container>
          <Header>
            #####################
            <Logo width={140} height={40} />
            #####################
          </Header>
          <Title>Order: {orderSession.orderId}</Title>

          <ReceiptDetails>
            <ReceiptTwoRow>
              <ReceiptLineItem>#{orderSession.orderId}</ReceiptLineItem>
              <ReceiptLineItem textAlign='right'>
                {/* eslint-disable-next-line @typescript-eslint/quotes */}
                {moment.unix(orderSession.timestamp / 1000).format("MMM D' YY H:mm")}
              </ReceiptLineItem>
            </ReceiptTwoRow>
            <ReceiptThreeRow>
              <ReceiptLineItem>QTY</ReceiptLineItem>
              <ReceiptLineItem>ITEM</ReceiptLineItem>
              <ReceiptLineItem textAlign='right'>TOTAL</ReceiptLineItem>

              <ReceiptLineItem>1</ReceiptLineItem>
              <ReceiptLineItem>{userPrompt}</ReceiptLineItem>
              <ReceiptLineItem textAlign='right'>6.90</ReceiptLineItem>
            </ReceiptThreeRow>
          </ReceiptDetails>

          <shared.LoadingPepe />

          <Title>Now Serving: {nowServingOrderId}</Title>
          <P>Thank you for your purchase!</P>
        </Container>

        <shared.ModalClose onClick={handleClose}>×</shared.ModalClose>
      </shared.ModalContainer>
    </ReactModal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  display-contents: center;
  align-items: center;
  gap: 10px;
  font-family: 'BIZ UDGothic', sans-serif;
  font-size: 16px;
  color: ${COLORS.black};
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  display-contents: center;
  align-items: center;
  font-size: 15px;
  gap: 4px;
  letter-spacing: 4px;
`

const Title = styled.div`
  font-weight: 500;
  letter-spacing: -0.03em;
  text-align: center;
  font-size: 25px;
`

const ReceiptDetails = styled.div`
  width: 100%;
  gap: 20px;
  display: flex;
  flex-direction: column;
`

const ReceiptTwoRow = styled.div`
  width: 100%;
  display: flex;
  display: grid;
  grid-template-columns: auto auto;
`

const ReceiptLineItem = styled.div<{ textAlign?: 'right' | 'left' }>`
  width: 100%;
  text-align: ${({ textAlign = 'left' }) => textAlign};
`

const ReceiptThreeRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
`

const P = styled.p`
  font-size: 14px;
`

const ModalPortalWrapper = styled(shared.ModalPortal)`
  &.ReactModal__Overlay--before-close {
    .modelOverlay {
      animation: none;
    }
  }
`

const ModalElementWrapper = styled(shared.ModalElement)`
  max-width: 310px;
  border-radius: 3px;
`
