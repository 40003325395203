import styled from 'styled-components/macro'

import { shared } from '@packages/ui'

import { COLORS } from 'src/shared/constants'
import { OrdinalCollectionList } from 'src/ordinals'

import { NavbarOffsetContainer } from './components'
import Bg from './assets/dining.svg'

const { Z_INDEX } = shared

export const OrdinalCollectionPage = () => {
  return (
    <NavbarOffsetContainer>
      <Head />
      <CollectionContainer>
        <OrdinalCollectionList />
      </CollectionContainer>
    </NavbarOffsetContainer>
  )
}

const Head = styled.div`
  z-index: ${Z_INDEX.headerLayer1};
  height: 53vw;
  width: 100%;
  background: url(${Bg}) no-repeat;
  background-size: 100%;
  background-position: bottom;
`

const CollectionContainer = styled.div`
  background-color: ${COLORS.mcPepeYellow};
  padding-bottom: 80px;
`
