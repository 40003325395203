export const RANDOM_PROMPTS = [
  'in a restaurant eating a cheeseburger',
  'painting a beautiful picture in art class',
  'as an italian plumber jumping on a mushroom',
  'in outer space, wearing a space suit',
  'as a scientist in a lab coat, holding a beaker, doing an experiment',
  'dueling with a laser sword on a desolate planet',
  'as a super powered alien doing martial arts',
  'mowing the lawn',
]

export const RANDOM_PROMPT_STYLES = [
  'intricate detail, 4k',
  'amazingly real',
  'high detail, 3d render',
  'pixel art',
  'volumetric lighting',
  'black and white',
  'watercolor',
  'cyberpunk',
  'anime',
  'graphic novel',
  'neon',
  'risograph',
  'synthwave',
]
