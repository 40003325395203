import { Route, Routes } from 'react-router-dom'

import { ROUTES } from './constants'
import { ScrollToTop } from './components'
import { DriveThroughPage } from './DriveThroughPage'
import { HomePage } from './HomePage'
import { InfoPage } from './InfoPage'
import { OrdinalCollectionPage } from './OrdinalCollectionPage'
import { NotFoundPage } from './NotFoundPage'
import { EmbeddedImagePage } from './EmbeddedImagePage'
// import { DiscordPage } from './DiscordPage'

export function PageRoutes() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path={ROUTES.home} element={<HomePage />} />
        <Route path={ROUTES.generate} element={<HomePage />} />
        <Route path={ROUTES.mint} element={<HomePage />} />
        <Route path={ROUTES.about} element={<InfoPage />} />
        <Route path={ROUTES.ordinals} element={<OrdinalCollectionPage />} />
        <Route path={ROUTES.driveThrough} element={<DriveThroughPage />} />
        <Route path={ROUTES.embeddedImage} element={<EmbeddedImagePage />} />
        {/* <Route path={'discord'} element={<DiscordPage />} /> */}
        <Route path='/*' element={<NotFoundPage />} />
      </Routes>
    </>
  )
}
