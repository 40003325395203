import styled from 'styled-components/macro'

import { shared } from '@packages/ui'

import { COLORS } from 'src/shared/constants'
import { FAQSection, CallToAction, About } from 'src/info'

import { NavbarOffsetContainer } from './components'
import Bg from './assets/dining.svg'
import Pattern from './assets/pattern.svg'

const { BREAKPOINTS, Z_INDEX } = shared

export const InfoPage = () => {
  return (
    <NavbarOffsetContainer>
      <Head />
      <AboutContainer>
        <About />
      </AboutContainer>
      <FAQContainer>
        <FAQSection />
      </FAQContainer>
      <CTAContainer>
        <CallToAction />
      </CTAContainer>
    </NavbarOffsetContainer>
  )
}

const Head = styled.div`
  z-index: ${Z_INDEX.headerLayer1};
  height: 53vw;
  width: 100%;
  background: url(${Bg}) no-repeat;
  background-size: 100%;
  background-position: bottom;
`

const AboutContainer = styled.div`
  margin-top: -5px; // seeing a slight gap between header image + about section
  background-color: ${COLORS.mcPepeYellow};
  padding-bottom: 80px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    padding-bottom: 30px;
  }
`

const FAQContainer = styled.div`
  background: url(${Pattern}) repeat;
  background-size: 200px;
`

const CTAContainer = styled.div`
  background-color: ${COLORS.mcPepeRed};
  padding: 80px 0px;
`
