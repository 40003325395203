import { UAParser } from 'ua-parser-js'

interface BrowerReq {
  name: string
  version: number
}

const WHITELISTED_BROWSERS: BrowerReq[] = [
  { name: 'Chrome', version: 90 },
  { name: 'Firefox', version: 102 },
]

const MINIMUM_CORES = 6

export function prefersReducedMotion() {
  // https://css-tricks.com/introduction-reduced-motion-media-query/
  const query = matchMedia('(prefers-reduced-motion: reduce)')
  return query.matches
}

// we are opting for a more defensive approach to using animations
// only tested browsers and newer devices will get shown the animations
// to prevent the site from being unusable due to slowness from the animations
export function shouldEnableAnimations(): boolean {
  if (prefersReducedMotion()) {
    return false
  }

  const parser = new UAParser()

  const parserResults = parser.getResult()

  if (parserResults.browser.name) {
    const foundBrowser = WHITELISTED_BROWSERS.find(
      (browser) => browser.name === parserResults.browser.name
    )

    if (
      foundBrowser &&
      parserResults.browser.version &&
      foundBrowser.version <= parseInt(parserResults.browser.version) &&
      navigator.hardwareConcurrency >= MINIMUM_CORES
    ) {
      return true
    }
  }

  return false
}
