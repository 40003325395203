import { BrowserRouter as Router } from 'react-router-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { useWeb3Modal, Web3Modal } from '@web3modal/react'
import { useAccount, WagmiConfig } from 'wagmi'
import React, { useEffect } from 'react'

import { shared, api } from '@packages/ui'

import { PageRoutes, Footer, Navbar } from './pages'
import { RECAPTCHA_V3_SITE_KEY, WALLET_CONNECT_PROJECT_ID } from './settings'
import { ethereumClient, getDefaultChain, wagmiConfig } from './web3'

const Providers = shared.nestComponents(Router)

export function App() {
  const { setDefaultChain } = useWeb3Modal()

  useAccount({
    onDisconnect() {
      console.log('disconnected')
      // resolves a bug with metamask
      // where if a user disconnects through metamask but not the UI
      // they are unable to connect again
      const userId = localStorage.getItem(api.USER_ID_LOCAL_STORAGE_KEY)
      localStorage.clear()
      if (userId) {
        localStorage.setItem(api.USER_ID_LOCAL_STORAGE_KEY, userId)
      }
      window.location.reload()
    },
  })

  useEffect(() => {
    if (setDefaultChain) {
      setDefaultChain(getDefaultChain())
    }
  }, [setDefaultChain])

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={RECAPTCHA_V3_SITE_KEY}
      useRecaptchaNet
      container={{
        // optional to render inside custom element
        element: 'reCaptchaBadge',
        parameters: {
          badge: 'bottomleft', // optional, default undefined
        },
      }}
    >
      <React.StrictMode>
        <WagmiConfig config={wagmiConfig}>
          <Providers>
            <Navbar />
            <PageRoutes />
            <div id='reCaptchaBadge' />
            <Footer />
          </Providers>
        </WagmiConfig>
        <Web3Modal projectId={WALLET_CONNECT_PROJECT_ID} ethereumClient={ethereumClient} />
      </React.StrictMode>
    </GoogleReCaptchaProvider>
  )
}
