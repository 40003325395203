import { useEffect, useRef, useState } from 'react'
import { useLocation, useSearchParams, createSearchParams, useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'
import { useAccount, useNetwork } from 'wagmi'
import { useWeb3Modal } from '@web3modal/react'

import { SignatureData, CompletedOrder } from '@packages/interfaces'
import { api, shared, nfts, orders } from '@packages/ui'

import { COLORS } from 'src/shared/constants'
import { DEPLOYED_CHAIN_ID, API_URL, NFT_BASE_URL, RECAPTCHA_V2_SITE_KEY } from 'src/settings'
import { ORDER_SEARCH_PARAMS, ROUTES } from 'src/pages/constants'
import { API_ENDPOINT } from 'src/apiEndpoints'

import { OrderRequest, OrderSession } from '../interfaces'
import { EXAMPLE_PROMPTS, RANDOM_PROMPTS, RANDOM_PROMPT_STYLES } from '../constants'
import { OrderReceiptModal } from './OrderReceiptModal'
import { OrderMintingModal } from './OrderMintingModal'

const { BREAKPOINTS, Z_INDEX } = shared

export function UserPromptForm() {
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const userId = api.useUserId()
  const [displayReCaptchaV2Checkbox, setDisplayReCaptchaV2Checkbox] = useState(false)
  const fetchWithReCaptcha = api.useFetchWithReCaptcha('order')
  const nowServingOrderId = orders.useNowServingOrderId(
    `${API_URL}${API_ENDPOINT.GET.nowServingOrderId}?type=inStore`
  )

  const { address } = useAccount()
  const { chain } = useNetwork()
  const { isOpen: isWeb3ModalOpen } = useWeb3Modal()

  const [userPrompt, setUserPrompt] = useState('')
  const [sanitizedUserPrompt, setSanitizedUserPrompt] = useState('')
  const userPromptInputRef = useRef<HTMLInputElement>(null)
  const [displayRequiredPromptError, setDisplayRequiredPromptError] = useState(false)

  const [orderRequest, setOrderRequest] = useState<OrderRequest>()
  const [isSubmittingOrder, setIsSubmittingOrder] = useState(false)
  const [orderSession, setOrderSession] = useState<OrderSession>()
  const [error, setError] = useState<api.ApiErrorResponse>()

  const [completedOrder, setCompletedOrder] = useState<CompletedOrder>()
  const [signatureData, setSignatureData] = useState<SignatureData>()

  const [isReceiptModalOpen, setIsReceiptModalOpen] = useState(false)
  const [hideReceiptModal, setHideReceiptModal] = useState(false)
  const [isMintingModalOpen, setIsMintingModalOpen] = useState(false)
  const [isCancelOrderModalOpen, setIsCancelOrderModalOpen] = useState(false)

  useEffect(() => {
    if (location.pathname === ROUTES.mint) {
      const imageFileName = searchParams.get(ORDER_SEARCH_PARAMS.imageFileName)
      const orderIdStr = searchParams.get(ORDER_SEARCH_PARAMS.orderId)
      const imageUrl = imageFileName ? nfts.getNFTImageUrl(imageFileName, NFT_BASE_URL) : ''
      const imageHash = imageFileName ? nfts.getImageHash(imageFileName) : undefined
      if (imageHash && orderIdStr && !isNaN(parseInt(orderIdStr))) {
        const orderId = parseInt(orderIdStr)
        if (!completedOrder || completedOrder.id !== orderId) {
          setCompletedOrder({
            id: orderId,
            uncompressedImageUrl: imageUrl,
            finalImageUrl: imageUrl,
            finalImageHash: imageHash,
            completedAtMs: Date.now(),
          })
        }
      }
      setIsMintingModalOpen(true)
    }
  }, [location.pathname])

  useEffect(() => {
    if (isMintingModalOpen || isCancelOrderModalOpen || error) {
      setHideReceiptModal(true)
    }
  }, [isMintingModalOpen, isCancelOrderModalOpen, error])

  function clearData() {
    navigate({
      pathname: ROUTES.generate,
    })
    setOrderRequest(undefined)
    setOrderSession(undefined)
    setCompletedOrder(undefined)
    setSignatureData(undefined)
    setError(undefined)
    setDisplayRequiredPromptError(false)
  }

  function closeReceiptModal() {
    clearData()
    setIsReceiptModalOpen(false)
  }

  function openReceiptModal() {
    setHideReceiptModal(false)
    setIsReceiptModalOpen(true)
  }

  function closeMintingModal() {
    clearData()
    // close receipt modal as well since its behind it
    setIsReceiptModalOpen(false)
    setIsMintingModalOpen(false)
  }

  function closeErrorModal() {
    // close receipt modal as well since its behind it
    setIsReceiptModalOpen(false)
    setError(undefined)
  }

  function closeCancelOrderModal() {
    setIsCancelOrderModalOpen(false)
  }

  async function onReCaptchaV2CheckboxChange(token: string | null) {
    if (token) {
      setDisplayReCaptchaV2Checkbox(false)
      if (orderRequest) {
        setIsSubmittingOrder(true)
        // delay submission so that we dont get rate limited
        await new Promise((r) => setTimeout(r, 2000))
        await submitOrder(orderRequest, token)
      }
    }
  }

  async function submitOrder(request: OrderRequest, reCaptchaV2Token?: string) {
    try {
      setIsSubmittingOrder(true)
      const now = Date.now()

      const response = await fetchWithReCaptcha(
        API_URL + API_ENDPOINT.POST.order,
        request,
        reCaptchaV2Token
      )
      const json = await response.json()

      if (response.status === 200) {
        setOrderSession({
          ...json,
          userPrompt,
          timestamp: now,
        })
        setOrderRequest(undefined)
        openReceiptModal()
      } else if (json && api.isApiErrorResponse(json)) {
        if (json.name === 'ReCaptchaV3FailedError') {
          setDisplayReCaptchaV2Checkbox(true)
        } else if (json.name === 'OrderInProgressError') {
          setIsCancelOrderModalOpen(true)
        } else {
          onError(json)
        }
      } else {
        throw new Error(json)
      }
    } catch (error) {
      console.error(error)
      onError({ name: 'UnknownError' })
    } finally {
      setIsSubmittingOrder(false)
    }
  }

  async function createOrder(event: React.SyntheticEvent) {
    event.preventDefault()
    if (isGenerateButtonDisabled()) {
      focusOnInputs()
      setDisplayRequiredPromptError(true)
      return
    }

    clearData()
    const request: OrderRequest = {
      userId,
      userPrompt,
      ...(address && chain?.id === DEPLOYED_CHAIN_ID && { address }),
    }
    setOrderRequest(request)
    await submitOrder(request)
  }

  function onError(error: api.ApiErrorResponse) {
    setError(error)
  }

  function onCompletedOrder(completedOrder: CompletedOrder, signatureData?: SignatureData) {
    navigate({
      pathname: ROUTES.mint,
      search: `?${createSearchParams({
        [ORDER_SEARCH_PARAMS.orderId]: `${completedOrder.id}`,
        [ORDER_SEARCH_PARAMS.imageFileName]: nfts.getImageFileName(
          completedOrder.uncompressedImageUrl
        ),
      })}`,
    })
    setCompletedOrder(completedOrder)
    setSignatureData(signatureData)
    setIsMintingModalOpen(true)
  }

  async function onReorder() {
    clearData()
    setIsMintingModalOpen(false)
    focusOnInputs()
  }

  function focusOnInputs() {
    userPromptInputRef.current?.focus()
    // userPromptTextAreaRef.current?.focus()
  }

  function isGenerateButtonDisabled(): boolean {
    if (
      !userPrompt ||
      sanitizedUserPrompt.length === 0 ||
      isSubmittingOrder ||
      displayReCaptchaV2Checkbox
    ) {
      return true
    }

    return false
  }

  return (
    <Container>
      <shared.UserPromptInput
        userPrompt={userPrompt}
        setUserPrompt={setUserPrompt}
        setSanitizedUserPrompt={setSanitizedUserPrompt}
        userPromptInputRef={userPromptInputRef}
        displayRequiredPromptError={displayRequiredPromptError}
        setDisplayRequiredPromptError={setDisplayRequiredPromptError}
        displayReCaptchaV2Checkbox={displayReCaptchaV2Checkbox}
        onReCaptchaV2CheckboxChange={onReCaptchaV2CheckboxChange}
        reCaptchaV2SiteKey={RECAPTCHA_V2_SITE_KEY}
        onSubmit={createOrder}
        isSubmitting={isSubmittingOrder}
        examplePrompts={EXAMPLE_PROMPTS}
        randomPrompts={{
          prompts: RANDOM_PROMPTS,
          styles: RANDOM_PROMPT_STYLES,
        }}
      />
      <ReceiptModalWrapper
        hideModalElement={hideReceiptModal}
        isModalOpen={isReceiptModalOpen && !isWeb3ModalOpen}
        closeModal={closeReceiptModal}
        nowServingOrderId={nowServingOrderId}
        orderSession={orderSession}
        onCompletedOrder={onCompletedOrder}
        onError={onError}
      />
      {completedOrder && (
        <OrderMintingModal
          isModalOpen={isMintingModalOpen && !isWeb3ModalOpen}
          closeModal={closeMintingModal}
          order={completedOrder}
          signatureData={signatureData}
          onReorder={onReorder}
          displayOverlay={!isReceiptModalOpen}
        />
      )}
      {error && (
        <orders.OrderErrorModal
          isModalOpen={true} // the error modal should always be open if an error is set
          closeModal={closeErrorModal}
          orderId={orderSession?.orderId}
          error={error}
          onReorder={onReorder}
          displayOverlay={!isReceiptModalOpen}
        />
      )}
      <orders.CancelOrderModal
        cancelOrderAPIUrl={`${API_URL}${API_ENDPOINT.POST.cancelOrderByUserId}`}
        onError={onError}
        isModalOpen={isCancelOrderModalOpen}
        closeModal={closeCancelOrderModal}
        recaptchaV2SiteKey={RECAPTCHA_V2_SITE_KEY}
      />
    </Container>
  )
}

const Container = styled.div`
  z-index: ${Z_INDEX.content};
  -webkit-transform: translate3d(0, 0, 0); // required for safari
  position: relative;
  background-color: ${COLORS.mcPepeYellow};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 50px 20px 30px 20px;

  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    padding: 20px;
  }
`

const ReceiptModalWrapper = styled(OrderReceiptModal)<{ hideModalElement: boolean }>`
  ${(props) =>
    props.hideModalElement &&
    css`
      .modalElement {
        opacity: 0%;
        transition: opacity 0.3s;
        transition-delay: ${shared.MODAL_ANIMATION_DURATION};
      }
    `}
`
