import { useState } from 'react'
import styled from 'styled-components/macro'
import { useAutoAnimate } from '@formkit/auto-animate/react'

import { shared } from '@packages/ui'

import { COLORS } from 'src/shared/constants'

import { faqs } from '../constants'
import { FAQ } from '../interfaces'

const { BREAKPOINTS } = shared

export function FAQSection() {
  const [displayedAnswerIndex, setDisplayedAnswerIndex] = useState(-1)

  return (
    <Container id='faq'>
      <Content>
        <Title>FAQ</Title>
        {faqs.map((faq, index) => (
          <Question
            key={shared.generateReactKey(faq.question)}
            faq={faq}
            index={index}
            displayedAnswerIndex={displayedAnswerIndex}
            setDisplayedAnswerIndex={setDisplayedAnswerIndex}
          />
        ))}
      </Content>
    </Container>
  )
}

function Question({
  faq,
  index,
  displayedAnswerIndex,
  setDisplayedAnswerIndex,
}: {
  faq: FAQ
  index: number
  displayedAnswerIndex: number
  setDisplayedAnswerIndex: (index: number) => void
}) {
  const [parent] = useAutoAnimate()
  return (
    <QuestionContainer
      onClick={() => {
        if (displayedAnswerIndex !== index) {
          setDisplayedAnswerIndex(index)
        } else {
          setDisplayedAnswerIndex(-1)
        }
      }}
      ref={parent}
    >
      <TextDiv>
        <StyledText>{faq.question}</StyledText>
        <ArrowWrapper facingDown={displayedAnswerIndex !== index} />
      </TextDiv>
      {displayedAnswerIndex === index && (
        <AnswerContainer>
          <Answer>{faq.answer}</Answer>
          {faq.links && (
            <Links>
              {faq.links.map((link) => (
                <LinkContainer>
                  <Link href={link.link} target='_blank' rel='noreferrer'>
                    {link.title}
                  </Link>
                </LinkContainer>
              ))}
            </Links>
          )}
        </AnswerContainer>
      )}
    </QuestionContainer>
  )
}

const Container = styled.div`
  margin: 0 auto;
  max-width: 900px;
  width: 100%;
  padding: 50px 20px;
  padding-bottom: 70px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    padding: 40px 30px;
    padding-bottom: 50px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`

const Title = styled(shared.Title)`
  font-size: 90px;
  line-height: 90px;
  color: ${COLORS.black};
  font-weight: 600;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 50px;
    line-height: 50px;
  }
`

const TextDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
`

const QuestionContainer = styled.div<{ ref: any }>`
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    opacity: 0.8;
  }
`

const ArrowWrapper = styled(shared.Arrow)`
  width: 45px;
  height: auto;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    width: 30px;
  }
`

const StyledText = styled.h2`
  font-size: 24px;
  color: ${COLORS.black};
  font-weight: 600;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 18px;
  }
`

const AnswerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Answer = styled.p`
  font-size: 18px;
  color: ${COLORS.black};
  font-weight: 500;
  white-space: pre-line;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 16px;
  }
`

const Links = styled.ul``

const LinkContainer = styled.li`
  display: list-item;
`

const Link = styled(shared.A)`
  font-size: 16px;
  color: rgb(71, 161, 255);
  font-family: 'Open Sans', sans-serif;
`
