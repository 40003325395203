import styled from 'styled-components/macro'
import { Helmet } from 'react-helmet'
import { Link, useParams } from 'react-router-dom'

import { shared, nfts, orders } from '@packages/ui'

import { NFT_BASE_URL } from 'src/settings'

import { NavbarOffsetContainer } from './components'
import { ROUTES } from './constants'

const { BREAKPOINTS } = shared

export function EmbeddedImagePage() {
  const { image } = useParams()
  const imageUrl = image ? nfts.getNFTImageUrl(image, NFT_BASE_URL) : ''
  const { width } = shared.useWindowDimensions()
  return (
    <NavbarOffsetContainer>
      <Container>
        <Helmet>
          <meta name='twitter:card' content='summary_large_image' />
          <meta name='twitter:site' content='@mcpepes_ai' />
          <meta name='twitter:title' content='Pepe title' />
          <meta name='twitter:description' content='Pepe description' />
          <meta name='twitter:image' content={imageUrl} />
        </Helmet>
        <PepeImg src={imageUrl} loadingHeight={`${orders.getOrderNFTImageHeight(width)}px`} />
        <Link to={ROUTES.generate}>
          <shared.Button>Order Your Own Pepe</shared.Button>
        </Link>
      </Container>
    </NavbarOffsetContainer>
  )
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 100px;
  gap: 15px;

  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    padding: 25px;
  }
`

const PepeImg = styled(shared.GracefullyLoadedImg)`
  max-width: 400px;
  border-radius: 30px;
  img {
    object-fit: contain;
    max-width: 400px;
  }
`
