import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

import { shared } from '@packages/ui'

import { COLORS } from 'src/shared/constants'
import { ROUTES } from 'src/pages/constants'

const { BREAKPOINTS } = shared

export function CallToAction() {
  return (
    <Container>
      <Content>
        <Title>Getting Hungry?</Title>
        <Link to={ROUTES.generate}>
          <Button>Order Now</Button>
        </Link>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  margin: 0 auto;
  max-width: 1300px;
  width: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Title = styled(shared.Title)`
  font-size: 40px;
  color: ${COLORS.white};
  font-weight: 600;
  margin-bottom: 25px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 50px;
    line-height: 60px;
  }
  @media only screen and (max-width: ${BREAKPOINTS.small}) {
    font-size: 36px;
    line-height: 60px;
  }
`

const Button = styled(shared.Button)`
  padding: 20px 55px;
  @media only screen and (max-width: ${BREAKPOINTS.small}) {
    padding: 15px 25px;
  }
`
