import styled, { css } from 'styled-components/macro'

import { shared, nfts } from '@packages/ui'

import { UserPromptForm } from 'src/orders'
import { COLORS } from 'src/shared/constants'
import { API_ENDPOINT } from 'src/apiEndpoints'
import { API_URL, NFT_BASE_URL } from 'src/settings'

import { Details, NavbarOffsetContainer } from './components'

const { BREAKPOINTS, Z_INDEX } = shared

export const HomePage = () => {
  const latestMints = nfts.useLatestMints(`${API_URL}${API_ENDPOINT.GET.latestMints}`, 4)

  const animationsEnabled = shared.shouldEnableAnimations()

  const pictures = latestMints.map((mint) => {
    const isGIF = nfts.isNFTExtension(mint, 'gif')
    return (
      <PictureFrame key={mint.tokenId} isGIF={isGIF}>
        <Picture
          fadeInTimeMs={1000}
          src={nfts.transformNFTImageUrl(mint.imageUrl, NFT_BASE_URL)}
          animationsEnabled={animationsEnabled}
          isGIF={isGIF}
        />
      </PictureFrame>
    )
  })

  return (
    <>
      <HomePageHead className='home-page-header' height={HEADER_HEIGHT} />
      <FramesContainer height={HEADER_HEIGHT}>
        <Frames>{pictures}</Frames>
      </FramesContainer>

      <NavbarOffsetContainer>
        <UserPromptForm />
        <Details />
      </NavbarOffsetContainer>
    </>
  )
}

const HEADER_HEIGHT = '40vw'

export const HomePageHead = styled.div<{ height: string }>`
  z-index: ${Z_INDEX.headerLayer3};
  position: absolute;
  height: ${(props) => props.height};
  width: 100%;
  background-size: 100vw;
  background-position: bottom;
`

export const FramesContainer = styled.div<{ height: string }>`
  background-color: ${COLORS.gray};
  width: 100%;
  height: ${(props) => props.height};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4vw;
  overflow: hidden;
  padding: 0vw 4vw 5vw 4vw;
`

export const Frames = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4vw;
  background: black;
  padding: 2vw;
  border: 0.5vw solid #404040;
  box-shadow: 14px 14px 25px #6f6f6f;
`

const ENABLE_GPU = css`
  // "trick" to enable GPU processing
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
`

const PictureFrame = styled.div<{ isGIF: boolean }>`
  z-index: ${Z_INDEX.headerLayer1};
  flex: 1;

  // crt styles
  position: relative;
  overflow: hidden;

  ${ENABLE_GPU}

  &::after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(65, 65, 65, 0.08);
    opacity: 0;
    z-index: ${Z_INDEX.headerLayer2};
    pointer-events: none;
  }
  &::before {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%),
      linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
    background-size: 100% 2px, 3px 100%;
    @media only screen and (max-width: ${BREAKPOINTS.medium}) {
      background-size: 100% 1px, 1px 100%;
    }
    z-index: ${Z_INDEX.headerLayer2};
    pointer-events: none;
  }
`

const Picture = styled(shared.GracefullyLoadedImg)<{ animationsEnabled: boolean; isGIF: boolean }>`
  position: relative;
  overflow: hidden;
  z-index: ${Z_INDEX.headerLayer1};
  border: 0.5vw solid;
  border-radius: 1.5vw;
  background-image: url('/static.gif');
  ${ENABLE_GPU}
  filter: blur(1px) brightness(1.1) saturate(0.8) contrast(1.2);

  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    filter: blur(0.7px) brightness(1.1) saturate(0.8) contrast(1.2);
  }

  ${(props) =>
    !props.isGIF &&
    css`
      &:before,
      &:after {
        display: block;
        pointer-events: none;
        content: '';
        position: absolute;
      }

      &:after {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: ${Z_INDEX.headerLayer2};
        background: linear-gradient(to bottom, transparent 50%, rgba(0, 0, 0, 0.1) 51%);
        background-size: 100% 5px;
      }
    `}

  ${(props) =>
    !props.isGIF &&
    props.animationsEnabled &&
    css`
      // scalines styles
      // disabled for now - due to issue where other animations are failing
      // &:after {
      //   animation: scanlines 1.5s steps(10) infinite;
      // }

      // @keyframes scanlines {
      //   0% {
      //     background-position: 0 50%;
      //   }
      // }

      // @media only screen and (max-width: ${BREAKPOINTS.medium}) {
      //   &:after {
      //     background: linear-gradient(to bottom, transparent 50%, rgba(0, 0, 0, 0.1) 51%);
      //     background-size: 100% 4px;
      //     animation: scanlines 1s steps(12) infinite;
      //   }
      // }
    `}
`
