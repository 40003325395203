import styled from 'styled-components/macro'

import { shared } from '@packages/ui'

import { COLORS } from 'src/shared/constants'

const { BREAKPOINTS } = shared

// Break paragraph text where needed when copy is finalized
export function About() {
  return (
    <Container>
      <Content>
        <Title>About</Title>
        <Text>
          <P>
            McPepe's has a vision to revolutionize the world of memes as we know it. The mission is
            simple, yet ambitious: and begins by bringing every possible variation of the legendary
            Pepe meme to the masses.
          </P>
          <P>
            With our custom AI infrastructure, we have the capacity to produce 2,000,000 Pepes each
            day. We believe that the true essence of the Pepe meme lies in its boundless creativity
            and adaptability. That's why we're excited to invite you to join us in the journey of
            bringing new Pepes to the world. By opening McPepe's to the public with free image
            generations on launch day, we're giving every creative mind a chance to contribute to
            the growth and evolution of the Pepe meme. If you want to keep your Pepe forever, mint
            it as an NFT into the official collection.
          </P>
        </Text>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  margin: 0 auto;
  max-width: ${BREAKPOINTS.large};
  width: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  gap: 30px;
`

const Title = styled(shared.Title)`
  font-size: 90px;
  line-height: 100px;
  color: ${COLORS.black};
  font-weight: 600;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 50px;
    line-height: 60px;
  }
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${BREAKPOINTS.large};
  width: 100%;
  padding: 35px 55px;
  background-color: ${COLORS.white};
  border-radius: 3vw;
  gap: 25px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    padding: 20px 30px;
  }
  @media only screen and (max-width: ${BREAKPOINTS.small}) {
    padding: 20px 20px;
  }
`

const P = styled.p<{ mb?: boolean }>`
  font-size: 24px;
  font-weight: 500;
  line-height: 43px;
  text-indent: 30px;
  color: ${COLORS.black};
  text-align: left;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 18px;
    line-height: 30px;
  }
  @media only screen and (max-width: ${BREAKPOINTS.small}) {
    font-size: 16px;
    line-height: 25px;
  }
`
