import { mainnet, goerli } from 'wagmi/chains'
import { configureChains, createConfig } from 'wagmi'
import { infuraProvider } from 'wagmi/providers/infura'
import { publicProvider } from 'wagmi/providers/public'
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'

import { DEPLOYED_CHAIN_ID, INFURA_API_KEY, WALLET_CONNECT_PROJECT_ID } from 'src/settings'

const chains = [mainnet, goerli]

// Wagmi client
const { publicClient } = configureChains(chains, [
  w3mProvider({ projectId: WALLET_CONNECT_PROJECT_ID }),
  infuraProvider({ apiKey: INFURA_API_KEY }),
  publicProvider(),
])

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({
    projectId: WALLET_CONNECT_PROJECT_ID,
    version: 2,
    chains,
  }),
  publicClient,
})

// Web3Modal Ethereum Client
export const ethereumClient = new EthereumClient(wagmiConfig, chains)

export function getDefaultChain() {
  switch (DEPLOYED_CHAIN_ID) {
    case 5:
      return goerli
    case 1:
    default:
      return mainnet
  }
}
