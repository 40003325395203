import ReactDOM from 'react-dom/client'
import { WagmiConfig } from 'wagmi'

import { shared } from '@packages/ui'

import { App } from './App'
import reportWebVitals from './reportWebVitals'
import { wagmiConfig } from './web3'
;(window as any).recaptchaOptions = {
  useRecaptchaNet: true,
}

const GlobalStyles = shared.createGlobalStyles({})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <>
    <WagmiConfig config={wagmiConfig}>
      <GlobalStyles />
      <App />
    </WagmiConfig>
  </>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
