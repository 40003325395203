import styled from 'styled-components/macro'
import { Link as _Link } from 'react-router-dom'

import { shared } from '@packages/ui'
import Telegram from '@packages/ui/src/shared/assets/social-logos/telegram.svg'
import Twitter from '@packages/ui/src/shared/assets/social-logos/twitter.svg'
import CoinMarketCap from '@packages/ui/src/shared/assets/social-logos/cmc.svg'
import Uniswap from '@packages/ui/src/shared/assets/social-logos/uniswap.svg'
import Discord from '@packages/ui/src/shared/assets/social-logos/discord.svg'
import Ordinals from '@packages/ui/src/shared/assets/social-logos/ordinals.png'
import { ReactComponent as OpenSea } from '@packages/ui/src/shared/assets/social-logos/opensea.svg'
import { ReactComponent as Medium } from '@packages/ui/src/shared/assets/social-logos/medium.svg'
import { ReactComponent as Github } from '@packages/ui/src/shared/assets/social-logos/github.svg'
import { ReactComponent as CoinGecko } from '@packages/ui/src/shared/assets/social-logos/coingecko.svg'

import Logo from 'src/shared/assets/logo-sign.png'
import { COLORS } from 'src/shared/constants'
import { LINKS } from 'src/settings'

import { ROUTES } from '../constants'

const { BREAKPOINTS, Z_INDEX } = shared

export function Footer() {
  return (
    <Container>
      <Content>
        <Link to={ROUTES.home}>
          <LogoImg src={Logo} />
        </Link>
        <FooterNav>
          <NavLinksContainer>
            <Link to={ROUTES.about}>About</Link>
            <Link to={ROUTES.about + '#faq'}>FAQ</Link>
          </NavLinksContainer>
          <SocialContainer>
            <Circle href={LINKS.telegram} target='_blank'>
              <SocialImg src={Telegram} />
            </Circle>
            <Circle href={LINKS.discord} target='_blank'>
              <SocialImg src={Discord} />
            </Circle>
            <Circle href={LINKS.twitter} target='_blank'>
              <SocialImg src={Twitter} />
            </Circle>
            <Circle href={LINKS.medium} target='_blank'>
              <Medium fill='white' />
            </Circle>
            <Circle href={LINKS.opensea.infinity} target='_blank'>
              <OpenSea width='40' height='40' />
            </Circle>
            <Link to={ROUTES.ordinals}>
              <Circle>
                <SocialImg src={Ordinals} />
              </Circle>
            </Link>
            <Circle href={LINKS.github} target='_blank'>
              <Github fill='white' width='33' height='33' />
            </Circle>
          </SocialContainer>
        </FooterNav>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  margin: 0 auto;
  background-color: ${COLORS.mcPepeYellow};
`

const Content = styled.div`
  margin: 0 auto;
  padding: 0px 20px;
  padding-bottom: 30px;
  max-width: 1300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  @media only screen and (max-width: ${BREAKPOINTS.large}) {
    padding-left: 15px;
    padding-right: 15px;
  }
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: column;
    justify-content: center;
    gap: 0px;
  }
`

const FooterNav = styled.div`
  padding-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    padding-top: 20px;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
  }
`

const NavLinksContainer = styled.div`
  display: flex;
  gap: 20px;
`

const SocialContainer = styled.div`
  z-index: ${Z_INDEX.priorityContent};
  display: flex;
  flex-flow: row wrap;
  gap: 15px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    justify-content: center;
    align-items: center;
  }
`

const LogoImg = styled.img`
  width: 160px;
  height: 115px;
`

const Circle = styled.a`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-color: ${COLORS.mcPepeRed};
  transition: 250ms;
  &:hover {
    opacity: 0.8;
  }
`

const SocialImg = styled.img`
  width: 30px;
`

const UniswapImg = styled.img`
  width: 33px;
  margin-top: -3px;
  margin-left: -1px;
`

const Link = styled(_Link)`
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  color: ${COLORS.black};
  transition: 0.2s;
  &:hover {
    opacity: 0.7;
  }
`
