import { ALT_HOME_PAGE_ENABLED } from 'src/settings'

export const ROUTES = {
  home: '/',
  generate: ALT_HOME_PAGE_ENABLED ? '/ai' : '/',
  mint: '/mint',
  about: '/about',
  faq: '/faq',
  ordinals: '/ordinals',
  driveThrough: '/drive-thru',
  embeddedImage: '/i/:image',
}

export const ORDER_SEARCH_PARAMS = {
  orderId: 'order-id',
  imageFileName: 'image-file-name',
}
