import styled from 'styled-components/macro'

import { shared } from '@packages/ui'

import { COLORS } from 'src/shared/constants'
import PhoneAndTakeoutImg from 'src/shared/assets/phone-and-takeout.svg'
import Telegram from 'src/shared/assets/telegram-invite.svg'
import Discord from 'src/shared/assets/discord-invite.svg'
import { LINKS } from 'src/settings'

const { BREAKPOINTS } = shared

export function Details() {
  return (
    <Container>
      <Content>
        <InfoContainer>
          <Title>On the go?</Title>
          <Subtitle>Order McPepe's on the McPepe's Drive Thru App</Subtitle>
          <ImgDiv>
            <ImgLink href={LINKS.discord} target='_blank' rel='noreferrer'>
              <DiscordImg src={Discord} />
            </ImgLink>
            <ImgLink href={LINKS.telegram} target='_blank' rel='noreferrer'>
              <TelegramImg src={Telegram} />
            </ImgLink>
          </ImgDiv>
        </InfoContainer>
        <ImageContainer>
          <TakeoutImgWrapper src={PhoneAndTakeoutImg} />
        </ImageContainer>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  padding: 30px;
  width: 100%;
`

const Content = styled.div`
  margin: 0 auto;
  max-width: 1300px;
  width: 100%;
  padding: 100px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    padding: 20px 0px 40px 0px;
    flex-flow: column;
    flex-direction: column-reverse;
    text-align: center;
  }
`

const InfoContainer = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1300px;
  width: 100%;
  gap: 40px;

  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    gap: 20px;
  }
`

const ImageContainer = styled.div`
  flex: 2;
`

const Title = styled(shared.Title)`
  font-size: 90px;
  line-height: 100px;
  color: ${COLORS.mcPepeYellow};
  font-weight: 600;

  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 50px;
    line-height: 60px;
  }
`

const Subtitle = styled.h3`
  color: ${COLORS.black};
  font-weight: 400;
  font-size: 24px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 18px;
  }
`

const ImgDiv = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    justify-content: center;
    text-align: center;
  }
`

const ImgLink = styled.a``

const Img = styled.img`
  height: auto;
`

const DiscordImg = styled(Img)`
  max-height: 67px;
`

const TelegramImg = styled(Img)`
  max-height: 67px;
`

const TakeoutImgWrapper = styled(Img)`
  width: 100%;
  max-width: 700px;
  height: auto;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    max-width: 450px;
  }
`
