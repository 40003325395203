import styled from 'styled-components/macro'
import React from 'react'
import { Link } from 'react-router-dom'
import { useAccount } from 'wagmi'
import { slide as Menu } from 'react-burger-menu'

import { shared } from '@packages/ui'
import Uniswap from '@packages/ui/src/shared/assets/social-logos/uniswap.svg'
import { Connect, Connected } from '@packages/web3'

import { COLORS } from 'src/shared/constants'
import MobileLogo from 'src/shared/assets/logo.svg'
import { LINKS } from 'src/settings'

import { ROUTES } from '../constants'

const { BREAKPOINTS, Z_INDEX } = shared

export function Navbar() {
  const { isConnected } = useAccount()

  return (
    <Container>
      <Head>
        <LogoContainer>
          <Link to={ROUTES.home}>
            <Logo src={MobileLogo} />
          </Link>
        </LogoContainer>
        <RightContent>
          <WalletContainer>{isConnected ? <Connected /> : <Connect />}</WalletContainer>
          <MenuWrapper>
            <Menu right customCrossIcon={<shared.ModalClose>×</shared.ModalClose>}>
              <Link className='menu-item' to={ROUTES.about}>
                About
              </Link>
              <Link className='menu-item' to={ROUTES.about + '#faq'}>
                FAQ
              </Link>
              <ExternalLink className='menu-item' href={LINKS.opensea.gen0} target='_blank'>
                Gen0
              </ExternalLink>
            </Menu>
          </MenuWrapper>
        </RightContent>
      </Head>
    </Container>
  )
}

export function NavbarOffsetContainer({ children }: { children: React.ReactNode }) {
  return <OffsetContainer>{children}</OffsetContainer>
}

const OffsetContainer = styled.div``

const Container = styled.div`
  z-index: ${Z_INDEX.headerLayer1};
  height: 75px;
  width: 100%;
  background-color: ${COLORS.mcPepeRed};
  color: white;
  display: flex;
  justify-content: center;
`

const Head = styled.div`
  width: 100%;
  height: 100%;
  max-width: ${BREAKPOINTS.large};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const RightContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    gap: 10px;
  }
  padding-right: 15px;
`

const ExternalLink = styled(shared.A)`
  font-size: 35px;
  color: ${COLORS.black};
`

const MenuWrapper = styled.div`
  /* Position and sizing of burger button */
  .bm-burger-button {
    position: relative;
    z-index: 0 !important;
    width: 36px;
    height: 30px;
    button {
      position: initial;
    }
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: ${COLORS.mcPepeYellow};
    border-radius: 2px;
    height: 4px !important;
    &:hover {
      opacity: 0.8;
    }
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    opacity: 0.8;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 30px !important;
    width: 30px !important;
  }

  /* Color/shape of close button cross */
  .bm-cross {
  }

  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    top: 0;
  }

  /* General sidebar styles */
  .bm-menu {
    font-family: 'IntroBold', sans-serif;
    background: white;
    padding: 20px;
    font-size: 35px;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: ${COLORS.black};
    padding: 0.8em;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
    color: ${COLORS.black};
    &:visited {
      color: inherit;
    }
    &:hover {
      opacity: 0.7;
    }
  }

  /* Styling of overlay */
  .bm-overlay {
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.7);
  }
`

const LogoContainer = styled.div`
  height: 100%;
  padding: 0px 15px;
`

const Circle = styled.a`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-color: ${COLORS.mcPepeYellow};
  transition: 250ms;
  &:hover {
    opacity: 0.8;
  }
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    display: none;
  }
`

const UniswapImg = styled.img`
  width: 45px;
  margin-top: -4px;
  margin-left: -1px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    width: 35px;
  }
`

const WalletContainer = styled.div``

const Logo = styled.img`
  height: 45px;
  margin-top: 15px;
  width: auto;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    height: 38px;
    margin-top: 19px;
  }
`
