// If more prompts are added the `createExamplePromptCSS` animation function must be adjusted
// prompts should also not be too long else animation will also need to be adjusted for height
export const EXAMPLE_PROMPTS = [
  'in a restaurant eating a cheeseburger, high detail, 3d render',
  'painting a beautiful picture in art class',
  'as an italian plumber jumping on a mushroom, pixel art',
  'in outer space, wearing a space suit, watercolor',
  'as a scientist in a lab coat, holding a beaker, doing an experiment',
  'dueling with a laser sword on a desolate planet, cyberpunk',
  'as a super powered alien doing martial arts, anime',
  'mowing the lawn',
]
