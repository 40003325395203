import styled, { css } from 'styled-components'
import { useState } from 'react'

import { COLORS } from '../constants'
import { StyledComponentProps } from '../interfaces'

import { Loading } from './Loading'

interface Props extends StyledComponentProps {
  src: string
  fadeInTimeMs?: number
  loadingHeight?: string
}

export function GracefullyLoadedImg({ src, fadeInTimeMs, loadingHeight, className }: Props) {
  if (!fadeInTimeMs) {
    fadeInTimeMs = 2000
  }
  const [imageLoaded, setImageLoaded] = useState(false)

  function onImageLoaded() {
    setImageLoaded(true)
  }

  return (
    <PlaceholderImage className={className} loaded={imageLoaded} loadingHeight={loadingHeight}>
      {!imageLoaded && <Loading />}
      <ImageLoader src={src} onLoad={onImageLoaded} />
      {imageLoaded && <Image src={src} fadeInTimeMs={fadeInTimeMs} />}
    </PlaceholderImage>
  )
}

const PlaceholderImage = styled.div<{ loaded?: boolean; loadingHeight?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5vw;
  height: 100%;
  width: 100%;
  transition: 250ms ease-in;
  background-color: ${({ loaded }) => (loaded ? COLORS.white : COLORS.black)};

  ${({ loaded = false, loadingHeight = '100%' }) =>
    !loaded &&
    css`
      height: ${loadingHeight};
    `}
`

const ImageLoader = styled.img`
  display: none;
`

const Image = styled.img<{ loaded?: boolean; fadeInTimeMs: number }>`
  height: 100%;
  width: 100%;
  object-fit: cover;

  animation: load ${({ fadeInTimeMs }) => fadeInTimeMs}ms;
  animation-fill-mode: both;

  @keyframes load {
    0% {
      filter: saturate(5%);
      opacity: 0;
    }

    50% {
      filter: saturate(70%);
      opacity: 100%;
    }

    100% {
      filter: brightness(100%);
      filter: saturate(100%);
    }
  }
`
