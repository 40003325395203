import styled from 'styled-components/macro'

import { DriveThroughForm } from 'src/drive-through'

import { NavbarOffsetContainer } from './components'

export function DriveThroughPage() {
  return (
    <NavbarOffsetContainer>
      <Container>
        <DriveThroughForm />
      </Container>
    </NavbarOffsetContainer>
  )
}

const Container = styled.div`
  height: 100%;
  width: 100%;
`
