import { shared } from '@packages/ui'

const { getBoolEnv, getEnv, getIntEnv } = shared

export const INFURA_API_KEY = getEnv('REACT_APP_INFURA_API_KEY')
export const DEPLOYED_CHAIN_ID = getIntEnv('REACT_APP_DEPLOYED_CHAIN_ID', 1)
export const WALLET_CONNECT_PROJECT_ID = getEnv('REACT_APP_WALLET_CONNECT_PROJECT_ID')

export const API_URL = getEnv('REACT_APP_ORDERS_API_URL')
export const NFT_BASE_URL = getEnv('REACT_APP_NFT_BASE_URL')

export const ERC721_MINTER_CONTRACT_ADDRESS = getEnv('REACT_APP_ERC721_MINTER_CONTRACT_ADDRESS')
export const ERC721_CONTRACT_ADDRESS = getEnv('REACT_APP_ERC721_CONTRACT_ADDRESS')
export const GOLDEN_TICKET_CONTRACT_ADDRESS = getEnv('REACT_APP_GOLDEN_TICKET_CONTRACT_ADDRESS')

export const RECAPTCHA_V3_SITE_KEY = getEnv('REACT_APP_RECAPTCHA_V3_SITE_KEY')
export const RECAPTCHA_V2_SITE_KEY = getEnv('REACT_APP_RECAPTCHA_V2_SITE_KEY')

export const ALT_HOME_PAGE_ENABLED = getBoolEnv('REACT_APP_ALT_HOME_PAGE_ENABLED', false)

export const LINKS = {
  discord: 'https://discord.gg/mcpepes',
  telegram: 'https://t.me/McPepes_Official_Portal',
  uniswap:
    'https://app.uniswap.org/#/swap?outputCurrency=0x6bf765c43030387a983f429c1438e9d2025b7e12&inputCurrency=eth',
  twitter: 'https://twitter.com/mcpepes_ai',
  twitterUsername: '@McPepes_AI',
  medium: 'https://mcpepes.medium.com',
  opensea: {
    gen0: 'https://opensea.io/collection/mcpepes',
    infinity: 'https://opensea.io/collection/mcpepes-infinity',
    goldenTickets: 'https://opensea.io/collection/mcpepes-golden-ticket', // TODO: fix url
  },
  coinmarketcap: 'https://coinmarketcap.com/currencies/mcpepes/',
  coingecko: 'https://www.coingecko.com/en/coins/mcpepe-s',
  github: 'https://github.com/McPepes/McPepes',
  embeddedImageUrl: 'https://i.mcpepes.com/',
}
