import styled, { css } from 'styled-components/macro'
import { useEffect, useState } from 'react'
import { ContractTransactionResponse } from 'ethers'
import ReactModal from 'react-modal'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { useWeb3Modal } from '@web3modal/react'
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi'

import {
  SignatureData,
  OrderAttestationRequest,
  CompletedOrder,
  MintImageAttestationPayload,
} from '@packages/interfaces'
import { api, shared, nfts, orders } from '@packages/ui'
import CardDeclined from '@packages/ui/src/shared/assets/card-declined.svg'
import { TransactionError, parseBlockchainError, useContract } from '@packages/web3'

import {
  NFT_BASE_URL,
  DEPLOYED_CHAIN_ID,
  ERC721_MINTER_CONTRACT_ADDRESS,
  API_URL,
  RECAPTCHA_V2_SITE_KEY,
  ERC721_CONTRACT_ADDRESS,
  LINKS,
} from 'src/settings'
import erc721PepeMinterAbi from 'src/web3/abis/ERC721PepeMinter.json'
import { COLORS } from 'src/shared/constants'
import { API_ENDPOINT } from 'src/apiEndpoints'

const { BREAKPOINTS, Z_INDEX } = shared

type Phase =
  | 'confirm'
  | 'connecting'
  | 'switching-network'
  | 'attestation'
  | 'signing'
  | 'minting'
  | 'tx-error'
  | 'error'
  | 'success'

interface Props extends shared.ModalProps {
  order: CompletedOrder
  signatureData?: SignatureData
  onReorder: () => Promise<void>
  displayOverlay: boolean
}

export function OrderMintingModal({
  isModalOpen,
  closeModal,
  order,
  signatureData: _signatureData,
  onReorder,
  displayOverlay,
}: Props) {
  const grecaptcha = api.useInstanceLoader(RECAPTCHA_V2_SITE_KEY)
  const [displayReCaptchaV2Checkbox, setDisplayReCaptchaV2Checkbox] = useState(false)
  const fetchWithReCaptcha = api.useFetchWithReCaptcha('signature')

  const [parent] = useAutoAnimate()
  const { width } = shared.useWindowDimensions()

  const { open } = useWeb3Modal()
  const { address } = useAccount()
  const { chain } = useNetwork()
  const { switchNetwork } = useSwitchNetwork({
    onError() {
      setPhase('confirm')
    },
  })

  const [minter] = useContract({
    address: ERC721_MINTER_CONTRACT_ADDRESS,
    abi: erc721PepeMinterAbi,
    requireSigner: true,
    deployedChainId: DEPLOYED_CHAIN_ID,
  })

  const [tokenId, setTokenId] = useState<number>()
  const [signatureData, setSignatureData] = useState<SignatureData>()
  const [tx, setTx] = useState<ContractTransactionResponse>()
  const [error, setError] = useState<api.ApiErrorResponse>()
  const [txError, setTxError] = useState<string>()
  const [phase, setPhase] = useState<Phase>('confirm')
  const [reordering, setReordering] = useState(false)

  useEffect(() => {
    if (_signatureData) {
      setSignatureData(_signatureData)
    }
  }, [_signatureData])

  useEffect(() => {
    if (
      (phase === 'connecting' || phase === 'switching-network' || phase === 'attestation') &&
      address &&
      minter
    ) {
      handleMint()
    }
  }, [address, minter])

  function resetData() {
    setPhase('confirm')
    setSignatureData(undefined)
    setTx(undefined)
    setError(undefined)
    setTxError(undefined)
    setTokenId(undefined)
  }

  async function onReCaptchaV2CheckboxChange(token: string | null) {
    if (token) {
      setDisplayReCaptchaV2Checkbox(false)
      // delay submission so that we dont get rate limited
      await new Promise((r) => setTimeout(r, 2000))
      await handleMint(token)
    }
  }

  function isMintButtonDisabled() {
    return displayReCaptchaV2Checkbox
  }

  function handleClose() {
    closeModal()
    resetData()
  }

  async function handleReorder() {
    if (!onReorder) return
    setReordering(true)
    await onReorder()
    resetData()
    setReordering(false)
  }

  function handleCancel() {
    resetData()
  }

  function handleTryAgainOnError() {
    resetData()
  }

  async function setErrorPhase(error: api.ApiErrorResponse) {
    setError(error)
    setPhase('error')
  }

  async function setTxErrorPhase(error: any) {
    let parsedError = parseBlockchainError(error)
    if (parsedError.includes('hash exists')) {
      parsedError = 'This image has already been minted'
    }
    setTxError(parsedError)
    setPhase('tx-error')
  }

  async function handleMint(reCaptchaV2Token?: string) {
    if (
      !(
        phase === 'confirm' ||
        phase === 'connecting' ||
        phase === 'switching-network' ||
        phase === 'attestation' ||
        phase === 'signing' ||
        reCaptchaV2Token
      )
    )
      return

    if (!address) {
      open()
      setPhase('connecting')
      return
    }

    if (chain?.id !== DEPLOYED_CHAIN_ID && switchNetwork) {
      try {
        await switchNetwork(DEPLOYED_CHAIN_ID)
        setPhase('switching-network')
        return
      } catch (error) {
        console.error(error)
        setErrorPhase({ name: 'UnknownError' })
        return
      }
    }

    let data = signatureData
    if (!data) {
      setPhase('attestation')
      try {
        const request: OrderAttestationRequest = {
          address,
          imageHash: order.finalImageHash,
          orderId: order.id,
        }

        const response = await fetchWithReCaptcha(
          API_URL + API_ENDPOINT.POST.signature,
          request,
          reCaptchaV2Token
        )
        const json = await response.json()

        if (response.status === 200) {
          data = json
        } else if (json && api.isApiErrorResponse(json)) {
          if (json.name === 'ReCaptchaV3FailedError') {
            setDisplayReCaptchaV2Checkbox(true)
          } else {
            setErrorPhase(json)
          }
          return
        } else {
          throw new Error(json)
        }
      } catch (error: any) {
        console.error(error)
        setErrorPhase({ name: 'UnknownError' })
        return
      }
    }

    if (!data) {
      setErrorPhase({ name: 'UnknownError' })
      return
    }

    if (!minter) {
      // minter object isnt created yet, will set phase to connecting
      // and useEffect above will recall handleMint once the minter object is set
      setPhase('connecting')
      return
    }

    let _tx: ContractTransactionResponse
    setPhase('signing')
    try {
      console.debug('minting', data)
      _tx = await minter.mint(
        data?.message.timestamp,
        (data?.message as MintImageAttestationPayload).imageHash,
        data?.signature
      )
    } catch (error: any) {
      console.error(error)
      setTxErrorPhase(error)
      return
    }

    setTx(_tx)
    setPhase('minting')
    try {
      const receipt = await _tx.wait()
      const log = receipt?.logs?.[0]
      const hexString = log && log.topics.length > 3 ? log.topics[3] : ''
      const bn = BigInt(hexString)
      setTokenId(parseInt(bn.toString()))
    } catch (error: any) {
      console.error(error)
      if (error.code === 'TRANSACTION_REPLACED' && error.reason === 'repriced') {
        setTx(error.replacement)
      } else {
        console.error(error)
        setTxErrorPhase(error)
        return
      }
    }
    setPhase('success')
  }

  if (!(isModalOpen && order)) return null

  let buttonText

  switch (phase) {
    case 'connecting':
    case 'switching-network':
    case 'signing':
      buttonText = 'See Wallet'
      break
    case 'attestation':
      buttonText = 'Preparing'
      break
    default:
      buttonText = 'Mint'
  }

  const canClose =
    phase === 'confirm' ||
    phase === 'connecting' ||
    phase === 'switching-network' ||
    phase === 'success' ||
    phase === 'error' ||
    phase === 'tx-error'

  const canCancel =
    phase === 'connecting' ||
    phase === 'switching-network' ||
    phase === 'attestation' ||
    phase === 'signing'

  return (
    <ReactModal
      isOpen={isModalOpen}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      ariaHideApp={false}
      className='modalElement'
      contentElement={(props, children) => (
        <ModalElementWrapper {...props}>{children}</ModalElementWrapper>
      )}
      overlayElement={(props, contentElement) => {
        const overlayProps = { ...props, displayOverlay }
        return (
          <ModalPortalWrapper {...overlayProps}>
            <shared.OverlayElement className='modelOverlay'>{contentElement}</shared.OverlayElement>
          </ModalPortalWrapper>
        )
      }}
    >
      <shared.ModalContainer>
        <Container>
          {(phase === 'confirm' ||
            phase === 'connecting' ||
            phase === 'switching-network' ||
            phase === 'signing' ||
            phase === 'attestation') && (
            <MintContainer ref={parent}>
              <PepeImg
                src={nfts.transformNFTImageUrl(order.uncompressedImageUrl, NFT_BASE_URL)}
                loadingHeight={`${orders.getOrderNFTImageHeight(width)}px`}
              />
              <Title>Order #{order.id} is Ready!</Title>
              {displayReCaptchaV2Checkbox && (
                <api.ReCaptchaCheckbox
                  onChange={onReCaptchaV2CheckboxChange}
                  siteKey={RECAPTCHA_V2_SITE_KEY}
                  grecaptcha={grecaptcha}
                />
              )}
              <ButtonsContainer>
                {!canCancel && (
                  <Button loading={reordering} onClick={handleReorder} border>
                    Re-order
                  </Button>
                )}
                {canCancel && <CancelButton onClick={handleCancel}>Cancel</CancelButton>}
                <MintButton
                  loading={canCancel}
                  showTextOnLoading
                  onClick={() => handleMint()}
                  disabled={isMintButtonDisabled()}
                >
                  {buttonText}
                </MintButton>
              </ButtonsContainer>
            </MintContainer>
          )}

          {phase === 'minting' && (
            <MintingContainer>
              <PepeImg
                src={nfts.transformNFTImageUrl(order.uncompressedImageUrl, NFT_BASE_URL)}
                showLoadingAnimation
                loadingHeight={`${orders.getOrderNFTImageHeight(width)}px`}
              />
              <Title showLoadingAnimation>Minting Order #{order.id}</Title>
              <P center>Please wait while the transaction confirms.</P>
            </MintingContainer>
          )}

          {phase === 'success' && (
            <MintingContainer>
              <PepeImg
                src={nfts.transformNFTImageUrl(order.uncompressedImageUrl, NFT_BASE_URL)}
                loadingHeight={`${orders.getOrderNFTImageHeight(width)}px`}
              />
              <shared.Confetti amount={16} color={BLUE_COLOR} />
              <Title>Order #{order.id} Minted!</Title>
              <ButtonsContainer>
                <Button loading={reordering} onClick={handleReorder} border>
                  Re-order
                </Button>
                <Button>
                  <shared.A
                    href={nfts.getOpenSeaNFTUrl(
                      chain?.id ?? 1,
                      tokenId ?? 0,
                      ERC721_CONTRACT_ADDRESS
                    )}
                    target='_blank'
                    rel='noreferrer'
                  >
                    View on OpenSea
                  </shared.A>
                </Button>
              </ButtonsContainer>
              <shared.TwitterShareLink
                tweetText={'Look what I made with ' + LINKS.twitterUsername}
                imageUrl={
                  LINKS.embeddedImageUrl + nfts.getImageFileName(order.uncompressedImageUrl)
                }
              />
            </MintingContainer>
          )}

          {phase === 'error' && (
            <MintingContainer>
              {error ? (
                <orders.OrderErrorMessage
                  error={error}
                  onTryAgain={handleTryAgainOnError}
                  orderId={order.id}
                />
              ) : (
                <div>Error</div>
              )}
            </MintingContainer>
          )}

          {phase === 'tx-error' && (
            <MintingContainer>
              <TransactionError txError={txError} tx={tx} onTryAgain={handleTryAgainOnError}>
                <img src={CardDeclined} width={230} />
                <TransactionErrorTitle>Card Declined :(</TransactionErrorTitle>
                <OrderId>Order #{order.id}</OrderId>
              </TransactionError>
            </MintingContainer>
          )}

          {canClose && <shared.ModalClose onClick={handleClose}>×</shared.ModalClose>}
        </Container>
      </shared.ModalContainer>
    </ReactModal>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    padding-top: 8px;
    padding-bottom: 0px;
  }
`

const MintContainer = styled.div<{ ref: any }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 10px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    gap: 8px;
  }
`

const MintingContainer = styled.div`
  min-height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    gap: 8px;
  }
`

const TransactionErrorTitle = styled(shared.Title)`
  font-size: 40px;
  line-height: 40px;
`

const Title = styled(shared.Title)<{ showLoadingAnimation?: boolean }>`
  text-align: center;
  font-size: 32px;
  line-height: 38px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 22px;
    line-height: 28px;
  }

  ${({ showLoadingAnimation = false }) =>
    showLoadingAnimation &&
    css`
      // only on firefox since it doesnt support @property animation
      @-moz-document url-prefix() {
        line-height: 60px;
        @media only screen and (max-width: ${BREAKPOINTS.medium}) {
          line-height: 45px;
        }
        color: transparent;
        background: linear-gradient(
          to left,
          ${BLUE_COLOR},
          ${PURPLE_COLOR},
          #3c67e3,
          ${PURPLE_COLOR},
          ${BLUE_COLOR}
        );
        background-size: 1000px 100%;
        animation: bg 45s linear infinite;
        background-clip: text;
        -webkit-background-clip: text;

        @keyframes bg {
          0% {
            background-position-x: 0;
          }
          100% {
            background-position-x: 10000px;
          }
        }
      }
    `}
`

const OrderId = styled.div`
  font-size: 22px;
  text-align: center;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 18px;
    line-height: 22px;
  }
`

const P = styled.p<{ bold?: boolean; center?: boolean }>`
  width: 100%;
  font-size: 16px;
  ${({ bold }) => (bold ? 'font-weight: bold' : '')};
  ${({ center }) => (center ? 'text-align: center' : '')};
`

const BLUE_COLOR = '#5ddcff'
const PURPLE_COLOR = '#4e00c2'

const PepeImg = styled(shared.GracefullyLoadedImg)<{
  showLoadingAnimation?: boolean
}>`
  max-width: 400px;
  border-radius: 30px;

  img {
    object-fit: contain;
    max-width: 400px;
  }

  ${({ showLoadingAnimation = false }) =>
    showLoadingAnimation && shared.getImageLoadingAnimation(BLUE_COLOR, '#3c67e3', PURPLE_COLOR)}
`

const ButtonsContainer = styled.div`
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    gap: 10px;
  }
`

const Button = styled(shared.Button)`
  max-width: 200px;
  flex: 1;
  font-size: 16px;
  a {
    font-size: 16px;
  }
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 14px;
    padding: 10px 8px;

    div {
      width: 18px;
      height: 18px;
    }
    a {
      font-size: 14px;
    }
  }
`

const MintButton = styled(Button)<{ loading?: boolean }>`
  ${(props) =>
    props.loading &&
    css`
      margin-left: 30%;
      background-color: ${COLORS.mcPepeYellow};
      z-index: ${Z_INDEX.content};
      &:hover {
        opacity: 1;
      }
      @media only screen and (max-width: ${BREAKPOINTS.medium}) {
        height: 41px;
        margin-left: 42%;
        margin-right: 3%;
      }
    `}
`

const CancelButton = styled(Button)`
  z-index: 0;
  position: absolute;
  text-align: left;
  justify-content: left;
  padding-left: 5%;
  padding-right: 25%;
  background-color: ${COLORS.mcPepeRed};
  margin-left: auto;
  margin-right: auto;
  left: -20%;
  right: 0;
  animation: unfurl 500ms linear;

  @keyframes unfurl {
    0% {
      left: 22%;
    }
    100% {
      left: -20%;
    }
  }

  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    padding-left: 10%;
    padding-right: 30%;
  }
`

const ModalPortalWrapper = styled(shared.ModalPortal)<{ displayOverlay: boolean }>`
  ${(props) =>
    !props.displayOverlay &&
    css`
      &.ReactModal__Overlay--after-open {
        .modelOverlay {
          animation: none;
        }
      }
    `}
`

const ModalElementWrapper = styled(shared.ModalElement)`
  max-width: 600px;
  padding: 20px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    gap: 15px;
  }
`
