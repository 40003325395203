import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

import { shared } from '@packages/ui'
import { ReactComponent as SadPepeImage } from '@packages/ui/src/shared/assets/pepe-sad.svg'

import { COLORS } from 'src/shared/constants'

import { NavbarOffsetContainer } from './components'
import { ROUTES } from './constants'

const { BREAKPOINTS } = shared

export function NotFoundPage() {
  return (
    <NavbarOffsetContainer>
      <Container>
        <Title>404 Not Found</Title>
        <SadPepe fill={COLORS.black} />
        <Link to={ROUTES.generate}>
          <shared.Button>Order Your Own Pepe</shared.Button>
        </Link>
      </Container>
    </NavbarOffsetContainer>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px;
  min-height: calc(100vh - 200px);
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    padding: 50px 20px;
    min-height: calc(100vh - 300px);
  }
`

const Title = styled(shared.Title)`
  font-size: 70px;
  line-height: 70px;
  color: ${COLORS.black};
  font-weight: 600;
  text-align: center;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 50px;
  }
`

const SadPepe = styled(SadPepeImage)`
  width: 300px;
  height: 300px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    width: 250px;
    height: 250px;
  }
`
