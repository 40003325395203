/* eslint-disable @typescript-eslint/quotes */
import { FAQ } from '../interfaces'

export const faqs: FAQ[] = [
  {
    question: 'What is the Gen0 Collection? What is the Infinite Collection?',
    answer: `The Gen0 collection contains the genesis mints generated at McPepe's.

      New mints are still available from McPepe's, but into the Infinite collection.
      All Gen0 card types and attributes are disabled until further notice to preserve their rarity.`,
    links: [
      { title: 'Gen0 Collection', link: 'https://opensea.io/collection/mcpepes' },
      { title: 'Infinite Collection', link: 'https://opensea.io/collection/mcpepes-infinity' },
    ],
  },
  {
    question: 'How does this work?',
    answer:
      'When you submit your prompt, it is put in a queue to be processed by our custom-trained Pepe AI model.',
  },
  {
    question: 'What are some tips for writing good prompts?',
    answer: `While not strictly necessary, forming a complete sentence starting with "Pepe" yields more consistently high quality results. If you want to include related keywords in your prompt, do so at the end separated by commas.
      
      Good prompt:
      
      Pepe eating a cheeseburger in a restaurant, oil on canvas, by Gustav Klimt
      
      Bad prompt:
      
      Gustav Klimt oil painting cheeseburger restaurant eating`,
  },
  {
    question: 'My image didn’t have any semblance to my prompt. What gives?',
    answer:
      "If you load too many details into the prompt, the AI won't be able to include everything. Also, make sure you spell all things correctly, otherwise the AI won't know what you're referring to! In some cases, the AI simply just simply doesn't have enough training with some terms, so they might be harder for it to generate. We encourage you to retry prompts multiple times. Sometimes you can get lucky!",
  },
  {
    question: 'My image didn’t have a Pepe. Help!',
    answer: `There are two main reasons a generated image does not include a Pepe. The most common is that your prompt doesn't include Pepe meaningfully in a sentence. While this isn't always required, not doing so can result in Pepe not appearing in your image. The second reason is that you included a word, term, or name that has very strong training associated with it. For example, the AI has seen "Mona Lisa" so often that including it in your prompt very often (although not always) overpowers Pepe. You can help mitigate this problem by putting these terms later in the prompt, or replacing them with relevant generic terms. For example:
      
      Pepe as Mona Lisa
      
      can be turned into
      
      Pepe by Leonardo Davinci, famous portrait, Mona Lisa
      
      or simply
      
      Pepe by Leonardo Davinci, famous portrait
      `,
  },
  {
    question: 'What is the purpose of McPepe’s NFTs?',
    answer:
      'McPepe’s NFTs allow users to save rare AI-generated Pepe the Frog images and store them as unique and scarce NFTs on the blockchain.',
  },
  {
    question: 'Does McPepe’s only serve rare pepe memes?',
    answer:
      'The current special is for pepe memes. The next season will be announced in the near future. What meme would you like to order next from the McPepe’s restaurant?',
  },
]
