import { useCallback, useEffect, useState } from 'react'

import { shared } from '@packages/ui'

const NOW_SERVING_POLLING_INTERVAL_MS = 5000

export function useNowServingOrderId(nowServingAPIUrl: string) {
  const [nowServingOrderId, setNowServingOrderId] = useState(0)

  useEffect(() => {
    getNowServing()
  }, [])

  shared.useInterval(async () => {
    await getNowServing()
  }, NOW_SERVING_POLLING_INTERVAL_MS)

  const getNowServing = useCallback(async () => {
    try {
      const response = await fetch(`${nowServingAPIUrl}`)
      const nowServingOrderId: number = await response.json()
      if (response.ok) {
        if (nowServingOrderId) {
          setNowServingOrderId(nowServingOrderId)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }, [setNowServingOrderId])

  return nowServingOrderId
}
